import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BroadcastState {
  currentPage: number;
  perPage: number;
  selectedDate: string | null;
}

const initialState: BroadcastState = {
  currentPage: 1,
  perPage: 20,
  selectedDate: null,
};

const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<string | null>) => {
      state.selectedDate = action.payload;
    },
    resetPagination: (state) => {
      state.currentPage = 1;
      state.perPage = 20;
      state.selectedDate = null;
    },
  },
});

export const { setCurrentPage, setPerPage, setSelectedDate, resetPagination } =
  broadcastSlice.actions;

export default broadcastSlice.reducer;
