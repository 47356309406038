import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { client } from 'apollo/client';
import {
  LogoutSessionWAMutation,
  LogoutSessionWAMutationDocument,
  LogoutSessionWAMutationVariables,
} from 'features/waAuth/__generated__/LogoutSessionWAMutation';
import {
  StartSessionWAMutation,
  StartSessionWAMutationDocument,
  StartSessionWAMutationVariables,
} from 'features/waAuth/__generated__/StartSessionWAMutation';
import {
  StopSessionWAMutation,
  StopSessionWAMutationDocument,
  StopSessionWAMutationVariables,
} from 'features/waAuth/__generated__/StopSessionWAMutation';
import {
  UpdateWhatsappMutationFn,
  UpdateWhatsappDocument,
  UpdateWhatsappVariables,
} from 'features/waAccounts/__generated__/UpdateWhatsappMutation';
import { EnumWhatsappStatus } from '__generated__/types';

interface SessionState {
  loading: boolean;
  error: string | null;
  activeOperations: number;
  totalOperations: number;
}

const initialState: SessionState = {
  loading: false,
  error: null,
  activeOperations: 0,
  totalOperations: 0,
};

export const startSession = createAsyncThunk('session/startSession', async (host: string) => {
  try {
    await client.mutate<StartSessionWAMutation, StartSessionWAMutationVariables>({
      mutation: StartSessionWAMutationDocument,
      variables: { host },
    });
    message.success(`Сессия ${host} запущена`);
  } catch (error) {
    message.error(`Не удалось запустить сессию ${host}`);
    throw error;
  }
});

export const stopSession = createAsyncThunk('session/stopSession', async (host: string) => {
  try {
    await client.mutate<StopSessionWAMutation, StopSessionWAMutationVariables>({
      mutation: StopSessionWAMutationDocument,
      variables: { host },
    });
    message.success(`Сессия ${host} остановлена`);
  } catch (error) {
    message.error(`Не удалось остановить сессию ${host}`);
    throw error;
  }
});

export const logoutSession = createAsyncThunk('session/logoutSession', async (host: string) => {
  try {
    await client.mutate<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>({
      mutation: LogoutSessionWAMutationDocument,
      variables: { host },
    });
    message.success(`Сессия ${host} сброшена`);
  } catch (error) {
    message.error(`Не удалось сбросить сессию ${host}`);
    throw error;
  }
});

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const randomDelay = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const startMultipleSessions = createAsyncThunk(
  'session/startMultipleSessions',
  async (hosts: string[]) => {
    const messageKey = 'multipleSessions';
    let completed = 0;

    message.loading({
      content: `Запуск сессий: 0 из ${hosts.length}`,
      key: messageKey,
      duration: 0,
    });

    for (const host of hosts) {
      try {
        await client.mutate<StartSessionWAMutation, StartSessionWAMutationVariables>({
          mutation: StartSessionWAMutationDocument,
          variables: { host },
        });
        completed++;
        message.loading({
          content: `Запуск сессий: ${completed} из ${hosts.length}`,
          key: messageKey,
          duration: 0,
        });
        await delay(randomDelay(3000, 5000));
      } catch (error) {
        message.error({
          content: `Ошибка запуска сессии ${host}`,
          key: messageKey,
        });
        throw error;
      }
    }

    message.success({
      content: 'Все сессии запущены',
      key: messageKey,
    });

    return hosts;
  }
);

export const stopMultipleSessions = createAsyncThunk(
  'session/stopMultipleSessions',
  async (hosts: string[]) => {
    const messageKey = 'multipleSessions';
    let completed = 0;

    message.loading({
      content: `Остановлено: 0 из ${hosts.length} сессий`,
      key: messageKey,
      duration: 0,
    });

    for (const host of hosts) {
      try {
        await client.mutate<StopSessionWAMutation, StopSessionWAMutationVariables>({
          mutation: StopSessionWAMutationDocument,
          variables: { host },
        });
        completed++;
        message.loading({
          content: `Остановлено: ${completed} из ${hosts.length} сессий`,
          key: messageKey,
          duration: 0,
        });
        await delay(500);
      } catch (error) {
        message.error({
          content: `Ошибка остановки сессии ${host}`,
          key: messageKey,
        });
        throw error;
      }
    }

    message.success({
      content: 'Все сессии остановлены',
      key: messageKey,
    });

    return hosts;
  }
);

export const logoutMultipleSessions = createAsyncThunk(
  'session/logoutMultipleSessions',
  async (hosts: string[]) => {
    const messageKey = 'multipleSessions';
    let completed = 0;

    message.loading({
      content: `Сброшено: 0 из ${hosts.length} сессий`,
      key: messageKey,
      duration: 0,
    });

    for (const host of hosts) {
      try {
        await client.mutate<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>({
          mutation: LogoutSessionWAMutationDocument,
          variables: { host },
        });
        completed++;
        message.loading({
          content: `Сброшено: ${completed} из ${hosts.length} сессий`,
          key: messageKey,
          duration: 0,
        });
        await delay(500);
      } catch (error) {
        message.error({
          content: `Ошибка сброса сессии ${host}`,
          key: messageKey,
        });
        throw error;
      }
    }

    message.success({
      content: 'Все сессии сброшены',
      key: messageKey,
    });

    return hosts;
  }
);

export const changeMultipleStatus = createAsyncThunk(
  'session/changeMultipleStatus',
  async ({ hosts, status }: { hosts: string[]; status: EnumWhatsappStatus }) => {
    const messageKey = 'multipleSessions';
    let completed = 0;

    message.loading({
      content: `Изменено: 0 из ${hosts.length} статусов`,
      key: messageKey,
      duration: 0,
    });

    for (const host of hosts) {
      try {
        await client.mutate<UpdateWhatsappMutationFn, UpdateWhatsappVariables>({
          mutation: UpdateWhatsappDocument,
          variables: { host, record: { status } },
        });
        completed++;
        message.loading({
          content: `Изменено: ${completed} из ${hosts.length} статусов`,
          key: messageKey,
          duration: 0,
        });
        await delay(200);
      } catch (error) {
        message.error({
          content: `Ошибка при изменения статуса ${host}`,
          key: messageKey,
        });
        throw error;
      }
    }

    message.success({
      content: 'Все статусы успешно изменены',
      key: messageKey,
    });
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startSession.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(startSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Произошла ошибка';
      })
      .addCase(stopSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(stopSession.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(stopSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Произошла ошибка';
      })
      .addCase(logoutSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutSession.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(logoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Произошла ошибка';
      })
      .addCase(startMultipleSessions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.activeOperations = 0;
        state.totalOperations = action.meta.arg.length;
      })
      .addCase(startMultipleSessions.fulfilled, (state) => {
        state.loading = false;
        state.activeOperations = 0;
        state.totalOperations = 0;
      })
      .addCase(stopMultipleSessions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.activeOperations = 0;
        state.totalOperations = action.meta.arg.length;
      })
      .addCase(stopMultipleSessions.fulfilled, (state) => {
        state.loading = false;
        state.activeOperations = 0;
        state.totalOperations = 0;
      });
  },
});

export default sessionSlice.reducer;
