import { Badge, ConfigProvider } from 'antd';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAuthFilter, setFilter, setHostFilter } from 'store/slices/filterSlice';
import { RootState } from 'store/store';
import { themeIsDark } from 'utils/appTheme';
import { ConnectionSessionWAList } from '../__generated__/ConnectionSessionWAListQuery';

interface AccountFilterProps {
  linkFilter: string;
  className?: string;
  allAccounts?: number;
  activeAccounts?: number;
  loading?: boolean;
  selectedAccounts?: (number | null)[] | null | undefined;
  accounts?: ConnectionSessionWAList | undefined;
}

export const AccountFilter = memo((props: AccountFilterProps) => {
  const {
    linkFilter,
    className,
    allAccounts,
    activeAccounts,
    loading,
    selectedAccounts,
    accounts,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filter, authFilter, hostFilter } = useSelector((state: RootState) => state.filter);

  const hostFilters = {
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    Phones: 'Phone',
  };

  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');
  const isWaChatsPage = location.pathname.includes('wachats');
  const isActive =
    (!isAuthorizePage && filter === linkFilter) ||
    (isAuthorizePage && authFilter === linkFilter) ||
    hostFilter === linkFilter.replace('_', '') ||
    hostFilter === linkFilter.replace('Phones', 'Phone');

  const handleFilterClick = (newFilter: string) => {
    if (isAuthorizePage) {
      if (newFilter in hostFilters) {
        dispatch(
          setHostFilter(
            hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
              ? ''
              : hostFilters[newFilter as keyof typeof hostFilters]
          )
        );
      } else {
        dispatch(setAuthFilter(authFilter === linkFilter ? '' : newFilter));
      }
    } else {
      if (newFilter in hostFilters) {
        dispatch(
          setHostFilter(
            hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
              ? ''
              : hostFilters[newFilter as keyof typeof hostFilters]
          )
        );
      } else {
        dispatch(setFilter(filter === linkFilter ? '' : newFilter));
        if (isWaChatsPage) {
          navigate('/wachats');
        }
      }
    }
  };

  const hasSelectedAccounts = accounts?.connectionSessionWAList
    ?.filter((acc) => selectedAccounts?.includes(acc?.waClient?.phone as number))
    ?.some((acc) => {
      const status = acc?.waClient?.status;
      const filterWithoutIcon = linkFilter.replace(/^[^A-Za-z]+/, '');

      return status === filterWithoutIcon;
    });

  return (
    <div className={`${className}`}>
      <ConfigProvider
        theme={{
          components: {
            Badge: {
              indicatorZIndex: 10,
              textFontWeight: '500',
            },
          },
          token: {
            colorBorderBg: 'transparent',
          },
        }}
      >
        <Badge
          count={(activeAccounts || allAccounts) && activeAccounts + '/' + allAccounts}
          size="small"
          className={`w-full select-none ${loading ? 'animate-pulse' : ''}`}
          style={{ color: '#fefefe' }}
          color={`${themeIsDark ? '#505b65' : '#6f6f6f'}`}
          overflowCount={400}
          offset={[-9, 0]}
        >
          <button
            onClick={() => handleFilterClick(linkFilter)}
            className={`${className} relative flex w-full select-none justify-center whitespace-nowrap rounded-2xl px-2 py-[9px] font-semibold duration-200 dark:text-white ${
              isActive ? 'bg-green-600' : 'inactiveLink'
            } ${hasSelectedAccounts ? 'ring-2 ring-blue-500' : ''}`}
          >
            <p className={`select-none ${isActive ? 'text-white' : 'dark:text-white'}`}>
              {linkFilter.includes('HARAMETSCKY') ? '🤵‍♂️HARAM' : linkFilter}
            </p>
            {hasSelectedAccounts && (
              <div className="absolute -right-1 -top-1 h-3 w-3 rounded-full bg-blue-500" />
            )}
          </button>
        </Badge>
      </ConfigProvider>
    </div>
  );
});
