// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
export type ConnectionSessionWA = { __typename: 'ClientStatus', statusWbot?: string | null, percent?: number | null, notification?: string | null, needPhone?: string | null, nameVitrualMachine?: string | null, linkCode?: string | null, versionCode?: string | null, waClient?: { __typename: 'ClientStatusWaClient', _id?: any | null, name?: string | null, phone?: number | null, email?: string | null, unreadCount?: number | null, status?: Types.EnumClientStatusWaClientStatus | null, needConnectQrCode?: boolean | null, profilePicUrl?: string | null, host?: string | null, zennoPosterHost?: string | null, retries?: number | null, qrcode?: string | null, novoreg_date?: any | null, banned_date?: any | null, progrev_good?: boolean | null, chatsCount?: number | null, adbHost?: string | null, messagesCount?: number | null } | null };

export type ClientStatusWaClient = { __typename: 'ClientStatusWaClient', _id?: any | null, name?: string | null, phone?: number | null, email?: string | null, unreadCount?: number | null, status?: Types.EnumClientStatusWaClientStatus | null, needConnectQrCode?: boolean | null, profilePicUrl?: string | null, host?: string | null, zennoPosterHost?: string | null, retries?: number | null, qrcode?: string | null, novoreg_date?: any | null, banned_date?: any | null, progrev_good?: boolean | null, chatsCount?: number | null, adbHost?: string | null, messagesCount?: number | null };

export const ClientStatusWaClient = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientStatusWaClient"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClientStatusWaClient"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"unreadCount"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"needConnectQrCode"}},{"kind":"Field","name":{"kind":"Name","value":"profilePicUrl"}},{"kind":"Field","name":{"kind":"Name","value":"host"}},{"kind":"Field","name":{"kind":"Name","value":"zennoPosterHost"}},{"kind":"Field","name":{"kind":"Name","value":"retries"}},{"kind":"Field","name":{"kind":"Name","value":"qrcode"}},{"kind":"Field","name":{"kind":"Name","value":"novoreg_date"}},{"kind":"Field","name":{"kind":"Name","value":"banned_date"}},{"kind":"Field","name":{"kind":"Name","value":"progrev_good"}},{"kind":"Field","name":{"kind":"Name","value":"chatsCount"}},{"kind":"Field","name":{"kind":"Name","value":"adbHost"}},{"kind":"Field","name":{"kind":"Name","value":"messagesCount"}}]}}]} as unknown as DocumentNode;
export const ConnectionSessionWA = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConnectionSessionWA"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClientStatus"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"waClient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ClientStatusWaClient"}}]}},{"kind":"Field","name":{"kind":"Name","value":"statusWbot"}},{"kind":"Field","name":{"kind":"Name","value":"percent"}},{"kind":"Field","name":{"kind":"Name","value":"notification"}},{"kind":"Field","name":{"kind":"Name","value":"needPhone"}},{"kind":"Field","name":{"kind":"Name","value":"nameVitrualMachine"}},{"kind":"Field","name":{"kind":"Name","value":"linkCode"}},{"kind":"Field","name":{"kind":"Name","value":"versionCode"}}]}},...ClientStatusWaClient.definitions]} as unknown as DocumentNode;