import { ExtendedClientStatus } from 'pages/authorizePage/authorizePage';
import { SortField, SortDirection } from 'store/slices/accountsSlice';

export const sortAccounts = (
  accounts: ExtendedClientStatus[],
  sorting: { field: SortField; direction: SortDirection }
): ExtendedClientStatus[] => {
  return [...accounts].sort((a, b) => {
    const hostA = a.waClient?.host || '';
    const hostB = b.waClient?.host || '';

    if (sorting.field === 'host' || !sorting.field) {
      const isPhoneA = hostA.startsWith('Phone');
      const isPhoneB = hostB.startsWith('Phone');
      const isPCA = hostA.startsWith('PC');
      const isPCB = hostB.startsWith('PC');

      if (isPhoneA && !isPhoneB) return -1;
      if (!isPhoneA && isPhoneB) return 1;
      if (isPCA && !isPCB) return -1;
      if (!isPCA && isPCB) return 1;

      if (isPhoneA && isPhoneB) {
        const numA = parseInt(hostA.replace('Phone_', '').split('_')[0], 10) || 0;
        const numB = parseInt(hostB.replace('Phone_', '').split('_')[0], 10) || 0;
        if (numA !== numB) return sorting.direction === 'asc' ? numA - numB : numB - numA;

        const wabA = parseInt(hostA.split('_WAB_')[1] || '0', 10);
        const wabB = parseInt(hostB.split('_WAB_')[1] || '0', 10);
        return sorting.direction === 'asc' ? wabA - wabB : wabB - wabA;
      }

      if (isPCA && isPCB) {
        const numA = parseInt(hostA.replace('PC', '').split('_')[0], 10) || 0;
        const numB = parseInt(hostB.replace('PC', '').split('_')[0], 10) || 0;
        if (numA !== numB) return sorting.direction === 'asc' ? numA - numB : numB - numA;

        const memuA = parseInt(hostA.split('_MEmu_')[1] || '0', 10);
        const memuB = parseInt(hostB.split('_MEmu_')[1] || '0', 10);
        return sorting.direction === 'asc' ? memuA - memuB : memuB - memuA;
      }

      const compareResult = hostA.localeCompare(hostB);
      return sorting.direction === 'asc' ? compareResult : -compareResult;
    }

    let compareResult = 0;
    switch (sorting.field) {
      case 'vps':
        compareResult = (a.nameVitrualMachine || '').localeCompare(b.nameVitrualMachine || '');
        break;
      case 'version':
        compareResult = (a.versionCode || '').localeCompare(b.versionCode || '');
        break;
    }

    return sorting.direction === 'asc' ? compareResult : -compareResult;
  });
};
