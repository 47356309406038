import { Divider } from 'antd';
import { AccountFilter } from 'features/waAccounts/components/accountFilter';
import { memo } from 'react';
import { FilterIcons } from 'shared/components/filterIcons/filterIcons';
import { useConnectionSessionWACount } from './__generated__/ConnectionSessionWACountQuery';

export const AccountFiltersList = memo(() => {
  const { data, loading } = useConnectionSessionWACount({ pollInterval: 5000 });

  if (loading) return <></>;

  type ConnectionSessionWACountItem = NonNullable<
    NonNullable<typeof data>['connectionSessionWACount']
  >[number];

  const renderFilterGroup = (
    filterCondition: (item: ConnectionSessionWACountItem | null) => boolean,
    customIconKey?: keyof typeof FilterIcons
  ) => {
    const filteredItems =
      data?.connectionSessionWACount?.filter((item) => filterCondition(item)) || [];
    const isOddCount = filteredItems.length % 2 !== 0;

    return filteredItems.map((item, index) => {
      const isLastInOddGroup = isOddCount && index === filteredItems.length - 1;

      let iconPrefix = '';
      if (customIconKey) {
        iconPrefix = FilterIcons[customIconKey];
      } else if (item?.status && FilterIcons[item?.status as keyof typeof FilterIcons]) {
        iconPrefix = FilterIcons[item?.status as keyof typeof FilterIcons];
      }

      return (
        <AccountFilter
          key={index}
          className={isLastInOddGroup ? 'col-span-2' : ''}
          linkFilter={iconPrefix + (item?.status || item?.host || '')}
          activeAccounts={item?.activeCount || 0}
          allAccounts={item?.allCount || 0}
        />
      );
    });
  };

  return (
    <div className="my-2 flex grid-cols-2 flex-col gap-2 2xl:grid">
      {renderFilterGroup((item) =>
        ['PC_01', 'PC_02', 'PC_03', 'PC_04', 'PC_05', 'PC_06', 'Phones'].includes(item?.host || '')
      )}

      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />

      {renderFilterGroup((item) =>
        ['WMMAIL', 'PROGREV', 'PROD', 'NEW'].includes(item?.status || '')
      )}

      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />

      {renderFilterGroup((item) => ['IT', 'BOSS', 'POISK'].includes(item?.status || ''))}

      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />

      {renderFilterGroup(
        (item) =>
          ['SAFRONOV', 'LOGINOV', 'YASHIN', 'LIPATOV', 'HARAMETSCKY'].includes(item?.status || ''),
        'PEOPLE'
      )}

      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />

      {renderFilterGroup((item) => ['NOVOREG', 'BANNED', 'QR'].includes(item?.status || ''))}
    </div>
  );
});
