import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { ExtendedClientStatus } from 'pages/authorizePage/authorizePage';
import { createSelector } from 'reselect';
import { RootState } from 'store/store';
import { sortAccounts } from 'utils/sortAccounts';

interface ErrorState {
  message: string | null;
  code?: string;
}

export type SortField = 'vps' | 'host' | 'version' | null;
export type SortDirection = 'asc' | 'desc' | null;

interface AccountsState {
  accounts: ConnectionSessionWAList | undefined;
  versions: Record<string, number> | undefined;
  statusCounts: {
    offline: number;
    online: number;
    pending: number;
    total: number;
  };
  loading: boolean;
  error: ErrorState | null;
  sorting: {
    field: SortField;
    direction: SortDirection;
  };
}

const initialState: AccountsState = {
  accounts: undefined,
  versions: undefined,
  statusCounts: {
    offline: 0,
    online: 0,
    pending: 0,
    total: 0,
  },
  loading: false,
  error: null,
  sorting: {
    field: 'host',
    direction: 'asc',
  },
};
export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<ConnectionSessionWAList | undefined>) => {
      state.accounts = action.payload;

      if (action.payload?.connectionSessionWAList) {
        state.versions = action.payload.connectionSessionWAList.reduce(
          (acc, current) => {
            const version = current?.versionCode ?? '';
            if (version) {
              acc[version] = (acc[version] || 0) + 1;
            }
            return acc;
          },
          {} as Record<string, number>
        );

        const counts = action.payload.connectionSessionWAList.reduce(
          (acc, current) => {
            const status = current?.statusWbot;

            if (!status) {
              acc.total += 1;
              return acc;
            }

            if (['closed', 'qr'].includes(status) || !status) {
              acc.offline += 1;
            } else if (['connected'].includes(status)) {
              acc.online += 1;
            } else if (['init', 'loading'].includes(status)) {
              acc.pending += 1;
            }

            acc.total += 1;
            return acc;
          },
          { offline: 0, online: 0, pending: 0, total: 0 }
        );

        state.statusCounts = counts;
      } else {
        state.versions = undefined;
        state.statusCounts = initialState.statusCounts;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<ApolloError | null>) => {
      state.error = action.payload
        ? {
            message: action.payload.message,
            code: action.payload.graphQLErrors[0]?.extensions?.code as string | undefined,
          }
        : null;
    },
    setSorting: (state, action: PayloadAction<{ field: SortField; direction: SortDirection }>) => {
      state.sorting = action.payload;
    },
  },
});

export const { setAccounts, setLoading, setError, setSorting } = accountsSlice.actions;
export default accountsSlice.reducer;

interface FilteredData {
  byStatus: {
    online: ExtendedClientStatus[];
    offline: ExtendedClientStatus[];
    pending: ExtendedClientStatus[];
    noStatus: ExtendedClientStatus[];
  };
  counts: {
    online: number;
    offline: number;
    pending: number;
    total: number;
  };
}

export const getFilteredAccounts = createSelector(
  [
    (state: RootState) => state.accounts.accounts?.connectionSessionWAList,
    (state: RootState) => state.filter.hostFilter,
    (state: RootState) => state.filter.vpsFilter,
    (state: RootState) => state.filter.versionFilter,
    (state: RootState) => state.filter.authFilter,
    (_state: RootState, phoneNumber?: string) => phoneNumber,
    (_state: RootState, _phone?: string, selected?: string[]) => selected,
    (state: RootState) => state.accounts.sorting,
  ],
  (
    accounts,
    hostFilter,
    vpsFilter,
    versionFilter,
    authFilter,
    phoneNumber,
    selected,
    sorting
  ): FilteredData => {
    if (!accounts) {
      return {
        byStatus: { online: [], offline: [], pending: [], noStatus: [] },
        counts: { online: 0, offline: 0, pending: 0, total: 0 },
      };
    }

    const result: FilteredData = {
      byStatus: { online: [], offline: [], pending: [], noStatus: [] },
      counts: { online: 0, offline: 0, pending: 0, total: 0 },
    };

    accounts.forEach((acc) => {
      if (!acc) return;

      const host = acc.waClient?.host || '';
      const name = acc.nameVitrualMachine || '';
      const version = acc.versionCode || '';

      if (
        !host.toLowerCase().includes(hostFilter.toLowerCase()) ||
        selected?.includes(host) ||
        !name.toLowerCase().includes(vpsFilter.toLowerCase()) ||
        !version.toLowerCase().includes(versionFilter.toLowerCase()) ||
        !host.replace('Phone', '').includes(phoneNumber?.toString() || '') ||
        (authFilter && !authFilter.includes(acc.waClient?.status?.toString() || ''))
      ) {
        return;
      }

      const extendedAcc = { ...acc, selected: false } as ExtendedClientStatus;

      if (!acc.statusWbot) {
        result.byStatus.noStatus.push(extendedAcc);
      } else if (['connected'].includes(acc.statusWbot)) {
        result.byStatus.online.push(extendedAcc);
        result.counts.online++;
      } else if (['closed', 'qr'].includes(acc.statusWbot)) {
        result.byStatus.offline.push(extendedAcc);
        result.counts.offline++;
      } else if (['init', 'loading'].includes(acc.statusWbot)) {
        result.byStatus.pending.push(extendedAcc);
        result.counts.pending++;
      }

      result.counts.total++;
    });

    // Применяем сортировку ко всем группам
    Object.keys(result.byStatus).forEach((key) => {
      result.byStatus[key as keyof typeof result.byStatus] = sortAccounts(
        result.byStatus[key as keyof typeof result.byStatus],
        sorting
      );
    });

    return result;
  }
);

export const getAccountAge = (diffInDays: number): string => {
  if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? 'день' : diffInDays < 5 ? 'дня' : 'дней'}`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `${months} ${months === 1 ? 'месяц' : months < 5 ? 'месяца' : 'месяцев'}`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `${years} ${years === 1 ? 'год' : years < 5 ? 'года' : 'лет'}`;
  }
};

export const getAccountLifespan = (novoregDate: Date, bannedDate: Date): string => {
  const diffInMs = Math.abs(bannedDate.getTime() - novoregDate.getTime());
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 7) {
    return `${diffInDays} ${diffInDays === 1 ? 'день' : diffInDays < 5 ? 'дня' : 'дней'}`;
  } else if (diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7);
    return `${weeks} ${weeks === 1 ? 'неделю' : weeks < 5 ? 'недели' : 'недель'}`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `${months} ${months === 1 ? 'месяц' : months < 5 ? 'месяца' : 'месяцев'}`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `${years} ${years === 1 ? 'год' : years < 5 ? 'года' : 'лет'}`;
  }
};

export const getAgeEmoji = (diffInDays: number): string => {
  if (diffInDays < 21) {
    return '👶';
  } else if (diffInDays < 60) {
    return '👨';
  } else {
    return '👴';
  }
};
