import SideMenu from 'shared/components/sideMenu';
import Header from 'widgets/header';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <main className="relative flex w-full gap-x-2 px-[6%] lg:px-[1%] xl:gap-x-4">
        <div className="pageBlock h-[calc(100vh-110px)] min-w-[250px]">
          <SideMenu />
        </div>

        <div className="flex h-[calc(100vh-110px)] w-full flex-row gap-x-2 xl:gap-x-4">
          {children}
        </div>
      </main>
    </>
  );
}
