// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWhatsappVariables = Types.Exact<{
  host: Types.Scalars['String']['input'];
  record: Types.UpdateByIdWhatsappInput;
}>;


export type UpdateWhatsapp = { __typename: 'Mutation', updateWhatsapp?: { __typename: 'Whatsapp', phone?: number | null, status?: Types.EnumWhatsappStatus | null } | null };


export const UpdateWhatsappDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateWhatsapp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"host"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"record"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateByIdWhatsappInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateWhatsapp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"host"},"value":{"kind":"Variable","name":{"kind":"Name","value":"host"}}},{"kind":"Argument","name":{"kind":"Name","value":"record"},"value":{"kind":"Variable","name":{"kind":"Name","value":"record"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateWhatsappMutationFn = Apollo.MutationFunction<UpdateWhatsapp, UpdateWhatsappVariables>;

/**
 * __useUpdateWhatsapp__
 *
 * To run a mutation, you first call `useUpdateWhatsapp` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhatsapp` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhatsapp, { data, loading, error }] = useUpdateWhatsapp({
 *   variables: {
 *      host: // value for 'host'
 *      record: // value for 'record'
 *   },
 * });
 */
export function useUpdateWhatsapp(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWhatsapp, UpdateWhatsappVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateWhatsapp, UpdateWhatsappVariables>(UpdateWhatsappDocument, options);
      }
export type UpdateWhatsappHookResult = ReturnType<typeof useUpdateWhatsapp>;
export type UpdateWhatsappMutationResult = Apollo.MutationResult<UpdateWhatsapp>;
export type UpdateWhatsappMutationOptions = Apollo.BaseMutationOptions<UpdateWhatsapp, UpdateWhatsappVariables>;