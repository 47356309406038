import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimCard, SimCardLifetimeHistory, Maybe } from '__generated__/types';

interface ErrorState {
  message: string | null;
  code?: string;
}

interface SimCardsState {
  simcards: SimCard[];
  loading: boolean;
  error: ErrorState | null;
  selectedSimCard: SimCard | null;
}

const initialState: SimCardsState = {
  simcards: [],
  loading: false,
  error: null,
  selectedSimCard: null,
};

export const simcardsSlice = createSlice({
  name: 'simcards',
  initialState,
  reducers: {
    setSimcards: (state, action: PayloadAction<SimCard[]>) => {
      state.simcards = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateSimcard: (state, action: PayloadAction<SimCard>) => {
      state.simcards = state.simcards.map((simcard) =>
        simcard._id === action.payload._id ? action.payload : simcard
      );
    },
    setError: (state, action: PayloadAction<ApolloError | null>) => {
      state.error = action.payload
        ? {
            message: action.payload.message,
            code: action.payload.graphQLErrors[0]?.extensions?.code as string | undefined,
          }
        : null;
    },
    setSelectedSimCard: (state, action: PayloadAction<SimCard | null>) => {
      state.selectedSimCard = action.payload;
    },
  },
});

export const { setSimcards, setLoading, setError, setSelectedSimCard, updateSimcard } =
  simcardsSlice.actions;
export default simcardsSlice.reducer;

export const getSimCardLifespan = (novoregDate: Date, bannedDate: Date): string => {
  const diffInMs = Math.abs(bannedDate.getTime() - novoregDate.getTime());
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 7) {
    return `${diffInDays} ${diffInDays === 1 ? 'день' : diffInDays < 5 ? 'дня' : 'дней'}`;
  } else if (diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7);
    return `${weeks} ${weeks === 1 ? 'неделю' : weeks < 5 ? 'недели' : 'недель'}`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `${months} ${months === 1 ? 'месяц' : months < 5 ? 'месяца' : 'месяцев'}`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `${years} ${years === 1 ? 'год' : years < 5 ? 'года' : 'лет'}`;
  }
};

export const getSimCardAge = (diffInDays: number): string => {
  if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? 'день' : diffInDays < 5 ? 'дня' : 'дней'}`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `${months} ${months === 1 ? 'месяц' : months < 5 ? 'месяца' : 'месяцев'}`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `${years} ${years === 1 ? 'год' : years < 5 ? 'года' : 'лет'}`;
  }
};

export const cleanLifetimeHistoryRecord = (record: Maybe<SimCardLifetimeHistory>) => ({
  novoreg_date: record?.novoreg_date,
  banned_date: record?.banned_date,
});
