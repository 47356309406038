import { ClientStatus } from '__generated__/types';
import { Badge, ConfigProvider } from 'antd';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHostFilter } from 'store/slices/filterSlice';
import { RootState } from 'store/store';
import { themeIsDark } from 'utils/appTheme';

interface Props {
  accs: ConnectionSessionWAList | undefined;
}

const HostFilters = ({ accs }: Props) => {
  const dispatch = useDispatch();
  const { hostFilter, authFilter } = useSelector((state: RootState) => state.filter);

  const filters = {
    Phones: 'Phone',
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    PC_07: 'PC07',
    PC_08: 'PC08',
  };

  const [filteredAccounts, setFilteredAccounts] = useState<
    (ClientStatus | null)[] | null | undefined
  >();

  useEffect(() => {
    const filteredAccounts = accs?.connectionSessionWAList
      ?.filter((acc) => {
        const status = acc?.waClient?.status?.toString() || '';
        const filter = authFilter || '';
        return status.includes(filter);
      })
      .map((acc) => acc as ClientStatus);
    setFilteredAccounts(filteredAccounts || null);
  }, [accs, authFilter]);

  const handleHostFilterChange = (value: string) => {
    dispatch(setHostFilter(hostFilter === value ? '' : value));
  };

  return (
    <motion.div
      key="host-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(filters).map(([key, value], index) => (
        <ConfigProvider
          key={index}
          theme={{
            token: {
              colorBorderBg: 'transparent',
              fontSize: 13,
              colorBgBase: themeIsDark ? 'white' : 'black',
            },
            components: {
              Badge: {
                indicatorZIndex: 20,
              },
            },
          }}
        >
          <Badge
            color={themeIsDark ? '#505b65' : '#eaf5ff'}
            count={
              authFilter
                ? filteredAccounts?.length
                : accs?.connectionSessionWAList?.filter((acc) =>
                    acc?.waClient?.host?.toLowerCase()?.includes(value.toLowerCase())
                  ).length
            }
            overflowCount={999}
          >
            <button
              key={key}
              onClick={() => handleHostFilterChange(value)}
              className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${hostFilter === value ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
            >
              <p
                className={`text-sm font-medium ${hostFilter === value ? 'text-white' : 'dark:text-white'}`}
              >
                {key}
              </p>
            </button>
          </Badge>
        </ConfigProvider>
      ))}
    </motion.div>
  );
};

export default HostFilters;
