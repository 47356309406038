import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showPhoneNumbers: localStorage.getItem('showPhoneNumbers') === 'true' || false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShowPhoneNumbers: (state, action) => {
      state.showPhoneNumbers = action.payload;
      localStorage.setItem('showPhoneNumbers', action.payload);
    },
  },
});

export const { setShowPhoneNumbers } = settingsSlice.actions;
export default settingsSlice.reducer;
