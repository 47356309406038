import { configureStore } from '@reduxjs/toolkit';
import clientsReducer from 'store/slices/clientsSlice';
import selectedAccountReducer from './slices/selectedAccountSlice';
import selectedClientReducer from './slices/selectedClientSlice';
import userReducer from './slices/userSlice';
import filterReducer from './slices/filterSlice';
import authReducer from './slices/authSlice';
import accountsReducer from './slices/accountsSlice';
import sessionReducer from './slices/sessionSlice';
import broadcastReducer from './slices/broadcastSlice';
import simcardsReducer from './slices/simcardsSlice';
import settingsReducer from './slices/settingsSlice';
export const store = configureStore({
  reducer: {
    clients: clientsReducer,
    selectedAccount: selectedAccountReducer,
    selectedClient: selectedClientReducer,
    user: userReducer,
    filter: filterReducer,
    auth: authReducer,
    accounts: accountsReducer,
    session: sessionReducer,
    broadcast: broadcastReducer,
    simcards: simcardsReducer,
    settings: settingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
